

const Hero = ({provider, countyname }) => {
    return(
        <div className="hero">
            <div className="providers-list-title">
              <h2>HCS PROGRAM PROVIDER INFORMATION</h2> 
              {/* {provider.length > 0 && <h5>{provider.length} PROVIDERS FOUND IN {countyname} COUNTY</h5>} */}
            </div> 
        </div>
    )
}
export default Hero;