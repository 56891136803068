import React from 'react';
import { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useHistory } from "react-router-dom";




const Countynames = () =>{
    let history = useHistory();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
  
    const filterProviders=(e)=>{
      console.log(e.currentTarget.textContent);
      history.push('/providersummary/' + e.currentTarget.textContent)
    }

    return(
        
        <div className="homepage container">
          <div className="cover black" data-color="black"></div>
            <div className="providers-list-intro-box">               
                <h1 className="providers-list">HCS Providers List</h1>
                <div className="content">
                  <h5 className="motto">Find a provider that is best for your individual needs.</h5>           
                  <p className="motto-subtext">Click select county below to find a provider</p><br/>
              {/* click below to find providers by county */}     
                </div>        
            </div>            
            <div className="providers-county-dropdown">              
            <Dropdown isOpen={dropdownOpen} toggle={toggle} >
              <DropdownToggle caret className="hcs-dropdown-btn btn-lg">
              Select County Name
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>COUNTY NAMES</DropdownItem>
                <DropdownItem onClick={filterProviders} className="text-uppercase">all</DropdownItem>
                <DropdownItem onClick={filterProviders}>AUSTIN</DropdownItem>
                <DropdownItem onClick={filterProviders}>BRAZORIA</DropdownItem>
                <DropdownItem onClick={filterProviders}>COLORADO</DropdownItem>
                <DropdownItem onClick={filterProviders}>FORT BEND</DropdownItem>                  
                <DropdownItem onClick={filterProviders}>GALVESTON</DropdownItem>
                <DropdownItem onClick={filterProviders}>HARRIS</DropdownItem>
                <DropdownItem onClick={filterProviders}>MATAGORDA</DropdownItem>
                <DropdownItem onClick={filterProviders}>WALLER</DropdownItem>
                <DropdownItem onClick={filterProviders}>WHARTON</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            </div>
          {/* TODO: add recent providers section */}
            
        </div>
        
    )
}  


export default Countynames