import React from "react";
import Homepage from "./Homepage";
import Providersummary from "./Providersummary"
import Providersdetails from "./Providers";
import About from "./About";
import Contact from "./Contact";
import { Switch, Route, Redirect } from "react-router-dom";

  const Main = () =>{
    return(          
            <div className="main">
                <div className="flex-shrink-0">
                    <Switch>
                        <Route exact path="/home" component={Homepage}/>                
                        <Route exact path="/providersummary/:countyname" component={Providersummary}/>
                        <Route exact path="/Providersdetails/:contract_number" component={Providersdetails}/>
                        <Route exact path="/About" component={About}/>   
                        <Route exact path="/Contact" component={Contact}/>   
                        <Redirect to="/home" />
                    </Switch>
                </div>   
            </div>  
    )
}
export default Main;

