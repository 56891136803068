import React from 'react';

const Footer = () =>{
    return(
        <footer className="footer align-self-center">
            <div className="container">
                <span className="text-muted mt-3">&copy; Copyright 2020-{new Date().getFullYear()}</span>
            </div>
        </footer>
        
    )
}

export default Footer;