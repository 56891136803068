import { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

const Navi = () =>{
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const id = "all"
    return(
        <header className="header">            
            <Navbar className="providerTbleHead" expand="md">
            <div className="container">
                <NavbarBrand href="/" className="navbar-brand d-flex align-items-center">
                        <span className="p-1 flex-fill">
                            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M13 2.05v3.03c3.39.49 6 3.39 6 6.92 0 .9-.18 1.75-.48 2.54l2.6 1.53c.56-1.24.88-2.62.88-4.07 0-5.18-3.95-9.45-9-9.95zM12 19c-3.87 0-7-3.13-7-7 0-3.53 2.61-6.43 6-6.92V2.05c-5.06.5-9 4.76-9 9.95 0 5.52 4.47 10 9.99 10 3.31 0 6.24-1.61 8.06-4.09l-2.6-1.53C16.17 17.98 14.21 19 12 19z"></path>
                            </svg>
                        </span>
                        <span className="p-2 flex-fill">
                            <div className="hcs-logo-name text-wrap" style={{width:'3rem'}} >HCS PROVIDERS NETWORK</div>
                        </span>
                </NavbarBrand>
                <NavbarToggler className="hcs-navbar" onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ms-auto p-2" navbar>
                            <NavItem>
                                <NavLink href="/home/">HOME</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={`/providersummary/${id}`} className="nav-link">PROVIDERS</NavLink>         
                            </NavItem>
                            <NavItem>
                                <NavLink href="/about/">ABOUT US</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/contact/">CONTACT US</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </div>
            </Navbar>
        </header>        
    )
}

export default Navi