import Navi from "./Navi";
// import {ReactComponent as Logo} from '../images/logo.svg';
const Header = () =>{
    return (
        <header className="header">            
            {/* <Logo className="logo" /> */}
            <Navi />
        </header>
    )
}
export default Header;