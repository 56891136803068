import React from 'react';
import Hero from './Hero';

const About = () =>{
    return(<div className="providers-full-detail-page">
    <div className="providers-details">          
        <Hero /> 
    <div>
          <div className="pb-5">              
            <div className="card">
              <div className="card-body container">
              <div id="hcs-details-page" className="card-header d-flex">
                <h4 className="align-self-center">ABOUT US</h4> 
              </div> 
              <div className="hcs-providers-page hcs-about-text">
                <p> This page was created as a guide for individuals with Intellectual Disabilities and their loved ones to seek HCS providers who will give the care needed for their daily life skills.</p>
                <p className="pb-5">The current system has its limitation with the providers list but with this version online the providers can showcase all activities they engage in for individuals to decide if it fits their care goal.</p>
              </div>
              </div>     
              </div>
          </div>
    </div>           
    
     </div>         
</div>
    )
}

export default About;