import { useState, useEffect} from 'react'
const useFetch = (url) => {  
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
      fetch(url)
      .then(res => {
        if (!res.ok) { // error coming back from server
          throw Error('could not fetch the data for that resource');
        } 
        return res.json();
      })
      .then(data => {
        setIsLoading(false);
        setData(data.result[0]);
        setError(null);
      })
      .catch(err => {
          // auto catches network / connection error
          setIsLoading(false);
          setError(err.message);
      })
      // check user geolocation
      // navigator.geolocation.getCurrentPosition(function(position) {
      // console.log(position)
      // console.log("Latitude is :", position.coords.latitude);
      // console.log("Longitude is :", position.coords.longitude);
      // });

  }, [url])

  return { data, isLoading, error };
}

export default useFetch;