import React from "react";
import { Link, useParams } from "react-router-dom";
import useFetch from "../shared/useFetch";
import { useHistory } from "react-router-dom";
import {FaHome, FaUser, FaMobileAlt, FaFax, FaGlobe } from "react-icons/fa";

const ProvidersFullDetails = () =>{
  const history = useHistory();
  const { contract_number } = useParams();
  const { data : hcsprovidersdetails, error, isLoading } = useFetch('https://np0ps10ya1.execute-api.us-east-1.amazonaws.com/Prod/contract-number?contract_number='+ contract_number);
 
  return(
      <div className="providers-full-detail-page">
          <div className="providers-details">          
            <div className="providers-list-title">
              { error && <div>{ error }</div> }
              { isLoading && <div className="hcs-loading position-absolute top-50 start-50 translate-middle">
                        Please give us a moment. We’re almost there!
                  </div> }
              <h2>HCS PROGRAM PROVIDER INFORMATION</h2>
            </div>  
          <div className="card"> 
                {/* added slice(0,1) to show only one provider since they are repeated with same value for counties */}
                {hcsprovidersdetails.slice(0, 1).map((hcsproviders, index) => (
                  <div className="card-body container" style={{display:"initial"}} key={index}>
                    <div id="hcs-details-page" className="card-header align-self-center">
                      <h3>{ hcsproviders.name } - { hcsproviders.contract_number }</h3> 
                    </div> 
                    
                    <div className="hcs-providers-page">
                      <p class="text-muted mb-3 fw-bold">PROVIDER INFORMATION</p>
                      <p className="hcs-providers-card-label"><FaUser /> APPLICANT CONTACT : <span className="hcs-providers-card-text">{ hcsproviders.applicant_name }</span></p>
                      <p className="hcs-providers-card-label">APPLICANT ID : <span className="hcs-providers-card-text">{ hcsproviders.provider_id }</span></p>
                      <hr/>
                      <p class="text-muted mb-3 fw-bold">CONTACT INFORMATION</p>
                      <p className="hcs-providers-card-label"><FaHome /> PHYSICAL ADDRESS : <span className="hcs-providers-card-text">{ hcsproviders.admin_address }</span></p>
                      <p className="hcs-providers-card-label"><FaMobileAlt /> PHONE : <span className="hcs-providers-card-text">{ hcsproviders.phone }</span></p>
                      <p className="hcs-providers-card-label"><FaFax /> FAX : <span className="hcs-providers-card-text">{hcsproviders.fax }</span></p>    
                      <hr/>
                      <p class="text-muted mb-3 fw-bold">OTHER INFORMATION</p> 
                      <div className="hcs-providers-counties">
                      <p className="hcs-providers-card-label"><FaGlobe /> STATEWIDE ENROLMENT : <span className="hcs-providers-card-text">{hcsproviders.statewide_enrolment }</span></p>
                      <p className="hcs-providers-card-label">CONSUMERS ENROLLED IN THE FOLLOWING COUNTIES : </p>
                      {hcsproviders.counties.map((othercounties, index) =>(
                          <div key={index}>
                            <Link className="providers-othercounties" to={`/providersummary/${othercounties.County}`}>{othercounties.County}  ({othercounties.Enrolment })</Link>  
                          </div>                          
                        ))}
                        
                      </div>            
                    </div>
                    <div className="card-footer" style={{display:"initial"}}>
                    {/* <Backbtn onClick={backtosummary}></Backbtn> */}
                    <Link onClick={() => history.goBack()} className="">GO BACK</Link>                    
                            </div>
                    </div>
               ))}
            </div>
          </div>
      </div>

      
  )
}

export default ProvidersFullDetails