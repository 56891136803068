import React from 'react';
import Hero from './Hero';

const Contact = () =>{
    return(<div className="providers-full-detail-page">
    <div className="providers-details">          
    <Hero /> 
    <div>
          <div className="pb-5">              
            <div className="card">
              <div className="card-body container">
              <div id="hcs-details-page" className="card-header d-flex">
                <h4 className="align-self-center">CONTACT US</h4> 
              </div> 
              <div className="hcs-providers-page hcs-about-text">
              <iframe title="contact us" src="https://docs.google.com/forms/d/e/1FAIpQLSdBZ6Xf60fXSBTiv-JHoVtaHn9Bpbq2R0SgBIf5tNPrHvGJOA/viewform?embedded=true" width="640" height="812" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
              </div>
              </div>     
              </div>
          </div>
    </div>           
    
     </div>         
</div>
    )
}

export default Contact;