import React from "react";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";


import { BrowserRouter } from 'react-router-dom';

const App = () =>{
  return(
      <BrowserRouter>
          <div id="main-container">
            <Header className="header" /> 
            <Main className="main" /> 
            <Footer className="footer"/>  
          </div>
      </BrowserRouter>      
  )
}

export default App;
