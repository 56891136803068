import { useEffect } from "react";
import { useState, useMemo, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import useFetch from "../shared/useFetch";
import {FaHome, FaUser, FaMobileAlt, FaFax, FaGlobe } from "react-icons/fa";
// import Pagination from './PaginationComponent';
  //let PageSize = 11;
const Showprovidersdata = () =>{
  const [pageSize, setPageSize] = useState(11);
  const { countyname } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setsearchTerm] = useState("");
  const [filterProviders, setFilterProviders] = useState([]);
  const { data : providers, error, isLoading } = useFetch('https://np0ps10ya1.execute-api.us-east-1.amazonaws.com/Prod/county?county='+countyname);    
  const currentTableData = useMemo(() => {
    if (searchTerm) {
      return providers.filter(provider =>
        provider.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    } else {
      return providers;
    }
  }, [providers, searchTerm]);
  useEffect(() => {
    setFilterProviders(filterProviders,currentTableData);
  }, [currentTableData, filterProviders]);
  const handlePageSizeChange = useCallback((event) => {
    setPageSize(event.target.value);
  }, []);
 
  //define pagination function
  const totalPages = useMemo(() => {
    return Math.ceil(currentTableData.length / pageSize);
  }, [pageSize, currentTableData]);
  //define pagination function
  const handlePrevious = useCallback(() => {
      setCurrentPage(currentPage - 1);
  }, [currentPage, setCurrentPage]);
  //define pagination function
  const handleNext = useCallback(() => {
      setCurrentPage(currentPage + 1);
  }, [currentPage, setCurrentPage]);
  //define pagination function
  const handleFirst = useCallback(() => {
      setCurrentPage(1);
  }, [setCurrentPage]);
  //define pagination function
  const handleLast = useCallback(() => {
      setCurrentPage(totalPages);
  }, [totalPages, setCurrentPage]); 
    if (error) {
          return {error};
          } else if (isLoading) {
              return <div className="hcs-loading position-absolute top-50 start-50 translate-middle">
                      Please give us a moment. We’re almost there!
                </div>;
          } else {         
      return(
        <div className="provider-summary-page">
            <div className="providers-details row">          
              <div className="providers-list-title">
                <h2>HCS PROGRAM PROVIDER INFORMATION</h2>              
                <h5 className="text-uppercase providers-found">{providers.length} PROVIDERS FOUND IN { countyname } COUNTY</h5>  
              </div>  
              <div className="container">
                {/* Page search and page size filter */}
                <div className="provider-search-filter row align-items-center">
                  <div className="col-md-7"><div className="provider-search position-relative me-auto">    
                                <label htmlFor="search-form">
                                          <input
                                              type="search"
                                              name="search-form"
                                              id="search-form"
                                              className="search-input"
                                              placeholder="Search by provider's name..."    
                                              aria-label="Search"                          
                                              onChange={(e) => {
                                                setsearchTerm(e.target.value);
                                              }}
                                          />
                                          <span className="visually-hidden">Search name here</span>
                                      </label>
                                </div> </div>
                  <div className="col-md-3 ms-auto">
                    <div className="provider-pagesize d-sm-flex">                                    
                    Display&nbsp;
                    <select
                        className="form-select form-select-sm"
                        aria-label="page size select"   
                        onChange={handlePageSizeChange}
                    >
                        <option value="25">25 Providers</option>
                        <option value="50">50 Providers</option>
                        <option value="75">75 Providers</option>
                        <option value="100">100 Providers</option>
                    </select>
                    </div> 
                  </div>
                </div>
              </div>  
              {/* Provider cards          */}
              <div className="container" id="cards-container">  
                <div className="provider-card-list">
                <p className="text-center">Providers summary. Click on "Show More Details" to learn more about each provider.</p>      
                  <div className="card-list">                  
                  {currentTableData.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((providers, index) => (                      
                    <div key={index} className="card-group">                          
                      <div className="card">
                      {/* <img src={`../images/${props.img}`} className="card--image"/> */}
                      <div className="card--header">            
                          <h2 className="card--title">{providers.name}</h2>
                          <hr/>
                      </div>
                      <div className="card--info">
                      <h4 className="card--contract-no"><b>CONTRACT NUMBER </b><br/><small className="contract-no">{providers.contract_number}</small></h4>
                          <p><b>PROVIDER ID</b><br/> {providers.provider_id}</p>
                          <p><FaUser /> <small><b>APPLICANT CONTACT</b></small><br/> {providers.applicant_name}</p>
                          <p><FaHome /> <small><b>PHYSICAL ADDRESS</b></small><br/> {providers.admin_address}</p>
                          <p><FaMobileAlt /> <small><b>PHONE</b></small><br/>{providers.phone}</p>
                          <p><FaFax /> <small><b>FAX</b></small><br/> {providers.fax}</p>
                          <p><FaGlobe /> <small><b>CONSUMERS ENROLLED STATEWIDE:</b></small><br/> {providers.statewide_enrolment}</p>            
                      </div>  
                      <div className="card-footer">
                                <Link to={`/Providersdetails/${providers.contract_number}`} id={providers.id} className="hcs-profile-card-footer">Show More Details</Link>
                            </div>             
                      </div>   
                                             
                    </div>    
                                          
                    ))}    
                    <div className="">
                          &nbsp;
                        </div>       
                </div>    
                              
                </div>
              <div className="providers-summary-pagination">
              <div className="col-md-12">
                        <ul className="pagination-container pagination-bar">
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleFirst}
                                    disabled={currentPage === 1}
                                >
                                    First
                                </button>
                            </li>
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handlePrevious}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                            </li>
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleNext}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                            </li>
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleLast}
                                    disabled={currentPage === totalPages}
                                >
                                    Last
                                </button>
                            </li>
                        </ul>
                    </div>
              </div>
              </div>      
            </div>         
        </div>
    )
    }
  
}
export default Showprovidersdata;
